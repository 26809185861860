.homepage-container {
  text-align: center;
  padding: 0 30px 0 30px;
  margin: 0 auto;
  max-width: 400px;
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  font-size: 100%;
  height: 100vh;
}

h1 {
  font-family: "Oswald", sans;
  font-size: 45px;
  font-weight: normal;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}

h3 {
  margin-bottom: 15px;
}
p {
  margin-bottom: 15px;
}

.create-account-button {
  color: white;
  font-size: 14px;
  font-weight: bold;
  background: #4c8ffb;
  border: 1px #3079ed solid;
  border-radius: 3px;
  padding: 10px;
  box-shadow: inset 0 1px 0 #80b0fb;
}

.create-account-button:hover {
  border: 1px #2f5bb7 solid;
  box-shadow: 0 1px 1px #eaeaea, inset 0 1px 0 #5a94f1;
  background: #3f83f1;
  cursor: pointer;
}

.create-account-button:active {
  box-shadow: inset 0 2px 5px #2370fe;
}
